import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Content from "../components/content"
import ShareLinks from "../components/ShareLinks"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const BlogPost = ({data, location}) => {
    const post = data.graphAPI.blogPost
    let baseurl = `https://fbmud57.com`
    return (
        <Layout>
            <SEO title={post.title} meta={[
                { name: `og:url`,
                content: `${baseurl}${location.pathname}` },
                { name: `og:type`,
                content: `article` },
                { name: `og:title`,
                content: `${post.title}` },
                { name: `og:description`,
                content: `${post.description}` },
                { name: `og:image`,
                content: `${baseurl}${post.heroImage.documentFile.childImageSharp.resize.src}` },
            ]}/>
                    <div className="title-section">
                        <h1 className="title is-size-1">{post.title}</h1>
                        <small style={{display: 'block', marginTop: '-1rem'}}>{moment(post.publishedAt).format("MMM D, YYYY")}</small>
                        <div class="dropdown is-hoverable" style={{marginTop: '2rem'}}>
                        <div class="dropdown-trigger">
                            <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                            <span>Share</span>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div class="dropdown-content">
                            <ShareLinks url={`${baseurl}${location.pathname}`} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <Content content={post.content} />
        </Layout>
    )
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: ID!) {
        graphAPI {
            blogPost(id: $id) {
                id
                title
                description
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            resize(width: 1024){
                                src
                            }
                        }
                    }
                }
                publishedAt
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`


